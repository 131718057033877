@import '~Styles/colors.module';
@import '~Styles/mixins.module';

.timeline_container {
  padding: 60px 0px 0px 60px;
  position: relative;
  overflow: hidden;

  .background_static_image {
    position: absolute;
    object-position: left top;
    object-fit: cover;
    opacity: 0.04;

    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
  }

  .background_image_container {
    position: absolute;
    top: 0;
    left: 0;
    // bottom: 0;
    // right: 0;
    width: 100vw;
    height: 100vh;

    // object-position: left top;
    z-index: 0;

    .background_image {
      object-fit: cover;
      width: 280px;
      position: absolute;
    }
  }

  .left_background_gradient {
    position: absolute;
    top: 0;
    left:0;
    bottom: 0;
    width: 30vw;
    z-index: 2;

    background-image: linear-gradient(to right, rgba(white, 0.1), rgba(0, 0, 0, 0));
  }

  .left_container {
    min-width: 40vw;
    padding-top: 6px;
    //    background-image: linear-gradient(rgba(0,0,0,0.3), transparent);
    z-index: 3;
    position: relative;

    .content_container {
      color: white;
      bottom: 0;
      margin: 0px 0px 40px 40px;
      position: absolute;

      .title {
        font-size: 60px;
      }

      .description_container {
        margin-top: 16px;
        margin-left: 22px;

        .description {
          font-size: 14px;
          margin-top: 14px;
        }

        .description:first-child {
          margin-top: 0;
        }
        
        .button {
          font-size: 14px;
          border-radius: 7.8px;
          margin-top: 22px;
          border: solid 1px white;
          padding: 3px 14px;
          @include on_click_button;
        }
      }
    }
  }


  .right_container {
    padding-left: 100px;
  }
}
