@import '~Styles/colors.module';
@import '~Styles/mixins.module';


.header_container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 70px;
  z-index: 1;
  padding: 0px 130px 0px 66px;
  
  .header_link_container {    
    .bodytype_container {
      //padding: 0px 6px;
  
      .header_link_button {
        color: white;
        font-size: 16px;
        transition: opacity 0.6s ease;
        &:hover {
          cursor: pointer;
          opacity: 0.4;
        }
      }
  
      .header_link_button:nth-child(2) {
        margin-left: 16px;
      }
    }
  
    .underline {
      height: 1px;
      width: 62px;
      background: white;
    }
  }
}

.header_container__increase_index {
  z-index: 2;
}

.hamburger_menu {
  display: none;
  height: 11px;
  width: 15px;
  justify-content: space-between;
  z-index: 4;
  @include on_click_button;

  .hamburger_row {
    background-color: white;
    border-radius: 10px;
    height: 3px;
    transform-origin: left;
    transition: transform 0.4s ease;
  }
}

.hamburger__selected {
  .hamburger_row:first-child {
    transform: rotateZ(45deg) translate(-2px, -2px);
  }

  .hamburger_row:last-child {
    transform: rotateZ(-45deg);
  }
}

.bubble_backdrop {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 3;

  background: rgba($color-dark, 0.2);
  backdrop-filter: blur(1.2px);
}

.speech_bubble_container {
  display: inline-block;
  position: relative;
  margin: 63px 0px 0px 17px;
  border-radius: 10px;
  padding: 10px 30px;
  background: white;
  box-shadow:	0 0.125rem 0.5rem rgba(0, 0, 0, .3), 0 0.0625rem 0.125rem rgba(0, 0, 0, .2);
}

.speech_bubble_container::before {
	content: '';
	position: absolute;
	width: 0;
	height: 0;
	bottom: 100%;
  left: 12px; // offset should move with padding of parent
  border: 7px solid transparent;
	border-top: none;

  border-bottom-color: #fff;
	filter: drop-shadow(0 -0.0625rem 0.0625rem rgba(0, 0, 0, .1));
}


@include medium {
  .header_container {
    padding: 0px 110px 0px 30px;
  }
  
  .hamburger_menu {
    display: flex;
  }
  .contact_container {
    display: none;
  }
}