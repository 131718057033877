@import '~Styles/colors.module';
@import '~Styles/mixins.module';

.company_logo_container {
  border-radius: 20px;
  height: 38px;
  background: $color-dark;
  position: relative;

  box-shadow: 0px 2px 3px 2px rgba(0, 0, 0, 0.1);
}

.onclick_selector {
  @include on_click_button;
}

.logo {
  height: 22px;
}

.first_logo_container {
  position: absolute;
  top: 0;
  left: 0;
  height: 38px;
  width: 38px;
}

.vertical_divider {
  width: 5px;
  height: 30px;
  background: $color-dark;
  margin: -1px 16.5px;
  z-index: 1;
  box-shadow: 0px 2px 3px 2px rgba(0, 0, 0, 0.1);
}

.title {
  white-space: nowrap;
  color: white;
}

@include medium {
  .container {
    flex-direction: row;
    padding: 20px;
    justify-content: space-around;
    flex-wrap: wrap;

    background: rgba(0,0,0, 0.3);
    border-radius: 10px;
  }

  .vertical_divider {
    display: none;
  }
}
