@import '~Styles/colors.module';

.landing_container {
    width: 100%;
    height: 100%;
    // background: $color-dark;
    position: relative;
    
    .body_container {
      padding-top: 70px;
      position: relative;
      overflow: hidden;

      .body_content_container {
        position: absolute;
        top:  0;
        left: 0;
        right: 0;
        bottom: 0;
      }
    }
}
