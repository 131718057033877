@import "~Styles/colors.module";
@import "~Styles/mixins.module";

.user_description_container {
  left: calc(50vw - 124px);
  padding: 80px 60px 30px;
  border-radius: 35px;
  box-shadow: 0px 5px 12px 3px rgba(0, 0, 0, 0.35);
  background-color: rgba(#ffffff, 0.87);
  max-width: 350px;
  position: absolute;
  z-index: 1;

  color: $color-dark;
  font-size: 13px;

  .contact_container {
    margin-top: 38px;
  }

  .user_description {
    text-align: center;

    .name {
      color: $color-dark;
      font-size: 14px;
    }
  }

  .user_button_container {
    margin-top: 20px;
    flex-wrap: wrap;

    .user_button {
      font-size: 14px;
      font-weight: bold;
      margin: 0px 3px;
      cursor: pointer;
      color: $color-dark;
      transition: transform 300ms ease-out;

      .underline {
        height: 2px;
        width: 100%;
        transition: width 500ms ease;
        background: $color-dark;
      }
    }

    .user_button:hover {
      transform: translateY(-2px);

      .underline {
        width: 10px;
      }
    }
  }
}

@include medium {
  .user_description_container {
    padding: 90px 35vw 20px;
    border-radius: unset;
    max-width: unset;
    left: unset;

    .user_button_container {
      margin-top: 20px;

      .user_button {
        margin-bottom: 0.5rem;
      }
    }

    .contact_container {
      margin-top: 30px;
    }
  }
}
